//
// Header
//

.header {
    position: relative;
}

.header-gradient {
    background: linear-gradient(87deg, #ab7a49 0, #eac7a3 100%) !important;
}
